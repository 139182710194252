import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { mutate } from 'swr';
import { BoxButton, IconButton } from '@instech/components';
import { SelectField } from '../shared/formFields/SelectField';
import { Label } from '../shared/formFields/Label';
import { InputField } from '../shared/formFields/InputField';
import { DatePicker } from '../shared/datePicker/DatePicker';
import { TriStateCheckbox } from '../shared/formFields/TriStateCheckbox';
import { Formik, Form, FieldArray } from 'formik';
import { ConfirmModalDialog } from '../shared/ConfirmModalDialog';
import { useEventStream } from '../../services/useEventStream';
import { useOrganization } from '../../services/useOrganization';
import { usePersonRolesById } from '../../services/usePersonRoles';
import { usePersonRoleTypes } from '../../services/usePersonRoleTypes';
import { useNationalities } from '../../services/useNationalities';
import { Close, Approved, Load, Add, Pen } from '@instech/icons';
import { getFriendlyDate, formatDateOfBirth } from '../../utils/date';
import { PersonRoleSection } from './PersonRoleSection';
import { usePersonById, putPerson } from '../../services/postPutPersonData';
import { processPostNewRole, processPutNewRole, removePersonRole } from './managePersonRoles';
import { ValidationSchemaEditPerson } from './validationSchema';
import { useAppContext } from '../appRouting/AppContext';
import { PromptUnsavedChanges } from '../shared/PromptUnsavedChanges';

const StyledClose = styled(Close)`
  float: right;
  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`;
const StyledLoad = styled(Load)`
  height: 20px;
`;
const StyledEdit = styled(Pen)`
  color: ${(props) => props.theme.marineBlue50};
  transition: color 1s ease-out;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.marineBlue};
  }
`;
const StyledApproved = styled(Approved)`
  color: ${(props) => props.theme.green};
`;
const Wrapper = styled.div`
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.marineBlue};
  box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};
  max-width: 60%;
  padding: 14px;
  margin: 32px auto;
`;

const Grid3 = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin: 30px 0;
`;
const Title = styled.h2`
  text-align: center;
`;
const SubTitle = styled.h3`
  margin: 0;
  color: ${(props) => props.theme.marineBlue};
`;
const SecondTitle = styled.h4`
  margin-bottom: 0;
`;
const SubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const Section = styled.div`
  background: ${(props) => props.theme.flatWhite};
  color: ${(props) => props.theme.black};
  padding: 20px;
  margin: 14px 0;
`;
const Buttons = styled.div`
  text-align: right;
  > * {
    margin-left: 14px;
  }
`;
const PersonsTable = styled.div`
  font-size: 14px;
  margin: 10px 0;
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.marineBlue};
`;
const TableHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid ${(props) => props.theme.marineBlue50};
  font-weight: bold;
  background: ${(props) => props.theme.flatWhite};
`;
const TableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  align-items: center;
  padding: 10px 20px;
  &:nth-child(odd) {
    background: ${(props) => props.theme.flatWhite};
  }
`;
const PepCheckbox = styled.div`
  justify-self: center;
  margin-top: 5px;
`;
const ErrorMessage = styled.div`
  color: red;
  padding: 5px 0;
`;
const SuccessMessage = styled.div`
  color: ${(props) => props.theme.green};
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: right;
`;
const LinkToCompany = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
`;

const setPersonRolesData = (roles, entityType, entityTypeId, person) => {
  const filteredRoles = roles.filter((role) => role.$type === entityType);
  let editedPersonRoles = {},
    othersPersonRoles = [];
  editedPersonRoles.roles = [];
  filteredRoles.forEach((item) => {
    if (item[entityType].id === entityTypeId) {
      editedPersonRoles[entityType] = item[entityType];
      editedPersonRoles.displayName = item[entityType].displayName;
      editedPersonRoles.person = person;
      editedPersonRoles.roles.push({
        id: item.role.id,
        activePeriod: item.role.activePeriod,
        type: item.role.type,
        isActive: item.role.isActive,
      });
    } else {
      othersPersonRoles.push(item);
    }
  });
  return { editedPersonRoles, othersPersonRoles };
};

const formatValuesForSubmit = (values, entityType, fleetId, insuredObjectId) => {
  const updatePerson = {
    data: {
      personId: values.person.id,
      nationalityId: values.person.nationality.id,
      firstName: values.person.firstName,
      lastName: values.person.lastName,
      dateOfBirth: formatDateOfBirth(values.person.dateOfBirth),
      isPoliticallyExposed: values.person.isPoliticallyExposed,
      eTag: values.person._etag,
    },
  };
  const updatePersonRoles = [];
  const createPersonRoles = [];
  values.roles &&
    values.roles.length > 0 &&
    values.roles.forEach((role, index) => {
      const roleObj = {};
      roleObj.roleType = role.type;
      roleObj.activePeriod = role.activePeriod;
      roleObj.etag = values[entityType]._etag;
      roleObj.personId = values.person.id;
      roleObj.isActive = role.isActive;
      roleObj.roleIndex = index;
      roleObj.isUbo = entityType === 'organization' ? false : true;
      if (entityType === 'organization') {
        roleObj.organizationId = values[entityType].id;
      } else {
        roleObj.fleetId = fleetId;
        roleObj.objectId = insuredObjectId;
      }
      if (role.isNew) {
        createPersonRoles.push(roleObj);
      } else {
        roleObj.personRoleId = role.id;
        updatePersonRoles.push(roleObj);
      }
    });
  return { updatePerson, updatePersonRoles, createPersonRoles };
};

const createInitialRole = () => ({
  activePeriod: {
    from: null,
    to: null,
  },
  type: '',
  isActive: true,
  isEditable: true,
  isNew: true,
});

const FormikChangeComponent = ({ values, setSubmitting }) => {
  useEffect(() => {
    setSubmitting(false);
  }, [values]);

  return null;
};

export const EditPerson = () => {
  const { singleCompanyId } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { fleetId, vesselId, clientId, personId } = useParams();
  const isUbo = location.pathname.includes('ubo');
  const locationState = { fleetId, vesselId, clientId };
  const pathName = fleetId
    ? `/fleets/${fleetId}`
    : !!singleCompanyId
    ? `/companies/${singleCompanyId}`
    : `/companies/${clientId}`;

  const { personData: person, mutate: mutatePersonData } = usePersonById(personId);

  const { data: personRoles, mutate: mutatePersonRoles } = usePersonRolesById(personId);
  const { data: organization, mutate: mutateOrganization } = useOrganization(clientId);

  const personName = person && person.firstName + ' ' + person.lastName;
  const roleTypes = usePersonRoleTypes();
  const nationalities = useNationalities();

  const [showConfirmRefresh, setShowConfirmRefresh] = useState(false);
  const [isPersonalSectionOpen, setIsPersonalSectionOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [updatedBy, setUpdatedBy] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isRemoving, setIsRemoving] = useState(false);

  const entityType = isUbo ? 'insuredObject' : 'organization'; // person role either of organization or object
  const entityTypeId = isUbo ? vesselId : clientId; //id either of organization or object

  const { editedPersonRoles, othersPersonRoles } =
    personRoles && personRoles.length > 0
      ? setPersonRolesData(personRoles, entityType, entityTypeId, person)
      : { editedPersonRoles: {}, othersPersonRoles: [] };

  const navigateBack = () => {
    navigate(pathName, { state: locationState, replace: true });
  };

  const eventStreamTopic = organization
    ? `/Organization/${organization.id}/${organization.id}/*`
    : `/InsuredObject/${fleetId}/${vesselId}/*`;

  const { onEvent, onConnect, onDisconnect, eventHandlerCleanup } = useEventStream(eventStreamTopic);

  onConnect(
    useCallback(() => {
      console.log('Connected to event stream for', eventStreamTopic);
    }, [eventStreamTopic])
  );

  onDisconnect(
    useCallback(() => {
      console.log('Disconnected from event stream for', eventStreamTopic);
    }, [eventStreamTopic])
  );

  useEffect(() => {
    onEvent((event) => {
      console.info('Received event on topic', eventStreamTopic, event);

      setUpdatedBy(event.user?.displayName);

      if (!isSaving) {
        if (isDirty) {
          setShowConfirmRefresh(true);
        } else {
          setShowConfirmRefresh(false);
          // Tell SWR to reload data from server
          mutatePersonRoles();
          mutateOrganization();
        }
      }
    });

    return eventHandlerCleanup;
  }, [mutateOrganization, mutatePersonRoles, eventStreamTopic, setShowConfirmRefresh, isSaving, isDirty]);

  const clickPersonalSectionHandler = () => {
    setIsPersonalSectionOpen((isPersonalSectionOpen) => !isPersonalSectionOpen);
  };

  const handleSubmit = async (values, setSubmitting) => {
    setErrorMessage(null);
    setIsDirty(false);
    setSubmitting(true);
    setIsSaving(true);
    setIsRemoving(false);
    setShowConfirmRefresh(false);

    const { updatePerson, updatePersonRoles, createPersonRoles } = formatValuesForSubmit(
      values,
      entityType,
      fleetId,
      vesselId
    );

    let latestEtag = values[entityType]._etag;

    if (!createPersonRoles) return;

    if (!isUbo)
      latestEtag = await processPostNewRole(
        createPersonRoles,
        latestEtag,
        null,
        setErrorMessage,
        setIsSaving,
        null,
        null,
        null
      );

    if (!updatePersonRoles) return;

    await processPutNewRole(updatePersonRoles, latestEtag, null, setErrorMessage, setIsSaving);

    if (!updatePerson) return;

    await putPerson(updatePerson.data.personId, updatePerson, updatePerson.data.eTag).then(setIsSaving(false));
    setIsSubmitted(true);
    setSubmitting(false);
    setShowConfirmRefresh(false);
    mutatePersonRoles();
    mutatePersonData();
    values.person && mutate(`Persons/${updatePerson.data.id}/roles`);
  };

  const keepChangesHandler = useCallback(() => {
    setShowConfirmRefresh(false);
    setUpdatedBy(null);
  }, [setShowConfirmRefresh]);

  const refreshHandler = useCallback(() => {
    setShowConfirmRefresh(false);
    setUpdatedBy(null);
    mutateOrganization();
    mutatePersonRoles();
  }, [mutateOrganization, mutatePersonRoles, setShowConfirmRefresh]);

  return (
    <Formik
      enableReinitialize
      initialValues={editedPersonRoles ? editedPersonRoles : {}}
      onSubmit={handleSubmit}
      validationSchema={ValidationSchemaEditPerson}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        dirty,
        isSubmitting,
        setSubmitting,
      } ) => {
        // Update isDirty state based on Formik's dirty prop directly in the form
        const handleChangeWrapper = (event) => {
          handleChange(event);
          setIsDirty(true);
        };
        return (
          <Form>
            <FormikChangeComponent values={values} setSubmitting={setSubmitting} />
            <PromptUnsavedChanges isDirty={ dirty && !isSubmitting } />
            {dirty && showConfirmRefresh && (
              <ConfirmModalDialog
                cancelButtonText="Keep unsaved changes"
                confirmButtonText="Refresh"
                onCancel={keepChangesHandler}
                onConfirm={refreshHandler}
              >
                {updatedBy ? (
                  <p>The company was updated on the server by {updatedBy}.</p>
                ) : (
                  <p>The company was updated on the server.</p>
                )}

                <p>Do you want to refresh with the latest data from the server, or keep your unsaved changes?</p>
              </ConfirmModalDialog>
            )}
            <Wrapper>
              <StyledClose data-test-id="navigate-back" onClick={navigateBack} />
              <Title>Edit Person {personName}</Title>
              <Section>
                <SubTitle>Person roles at {values.displayName}</SubTitle>
                <FieldArray name="roles">
                  {({ push, remove }) => {
                    return (
                      <div>
                        {values?.roles &&
                          values.roles.length > 0 &&
                          values.roles.map((role, index) => {
                            return (
                              <div key={index}>
                                <PersonRoleSection
                                  role={role}
                                  roles={values.roles}
                                  index={index}
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldTouched}
                                  roleTypes={roleTypes}
                                  handleRemove={() => {
                                    removePersonRole(
                                      index,
                                      remove,
                                      role.id,
                                      values[entityType]._etag,
                                      isUbo,
                                      fleetId,
                                      vesselId,
                                      clientId,
                                      setIsRemoving,
                                      setIsDirty,
                                      null,
                                      null,
                                      mutatePersonRoles
                                    );
                                  }}
                                  touched={touched}
                                  errors={errors}
                                  errorMessage={errorMessage}
                                  isDisabled={!role.isEditable}
                                  handleRoleChange={() => {
                                    setSubmitting(false);
                                  }}
                                />
                              </div>
                            );
                          })}

                        {!isUbo && (
                          <BoxButton
                            data-test-id="add-role"
                            aria-label="Add Role"
                            onClick={() => {
                              setErrorMessage(null);
                              push(createInitialRole());
                              const roleIndex = values.roles && values.roles.length > 0 ? values.roles.length : 0;
                              setFieldTouched(`roles[${roleIndex}].type`);
                            }}
                            padding="10px 20px"
                            startIcon={<Add />}
                            type="button"
                          >
                            ROLE
                          </BoxButton>
                        )}
                      </div>
                    );
                  }}
                </FieldArray>
              </Section>
              <Section>
                <SubTitleWrapper>
                  <SubTitle>Personal Information</SubTitle>
                  {person && (
                    <IconButton
                      data-test-id="edit-person-info"
                      ariaLabel="Edit personal information"
                      icon={<StyledEdit />}
                      onClick={clickPersonalSectionHandler}
                      type="button"
                    />
                  )}
                </SubTitleWrapper>
                <div>Editing personal information will update this person in all roles.</div>
                {isPersonalSectionOpen && (
                  <Grid3>
                    <div>
                      <Label label="First Name" />
                      <InputField
                        name={`person.firstName`}
                        value={values.person.firstName}
                        type="text"
                        handleChange={handleChangeWrapper}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                      />
                    </div>
                    <div>
                      <Label label="Last Name" />
                      <InputField
                        name={`person.lastName`}
                        value={values.person.lastName}
                        type="text"
                        handleChange={handleChangeWrapper}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                      />
                    </div>
                    <div>
                      <Label label="Nationality" />
                      <SelectField
                        name={`person.nationality`}
                        placeholder="Not selected"
                        options={nationalities}
                        value={values.person.nationality?.id || null}
                        displayNameSelector={(x) => `${x.name}  (${x.twoLetterCountryCode})`}
                        valueNameSelector={(x) => x.id}
                        handleChange={(name, value) => {
                          const nationality = nationalities.find((n) => n.id === value);
                          const newValue = !!nationality
                            ? {
                                id: nationality.id,
                                displayName: nationality.name,
                                partitionKey: nationality.twoLetterCountryCode,
                              }
                            : null;
                          setFieldValue(name, newValue);
                        }}
                        handleBlur={setFieldTouched}
                        touched={touched}
                        errors={errors}
                      />
                    </div>
                    <div>
                      <Label label="Date of Birth" />
                      <DatePicker
                        name={`person.dateOfBirth`}
                        placeholder="Select a date"
                        value={values.person.dateOfBirth}
                      />
                    </div>
                    <div>
                      <Label label="PEP" />
                      <PepCheckbox>
                        <TriStateCheckbox
                          name={`person.isPoliticallyExposed`}
                          value={values.person.isPoliticallyExposed}
                          onClick={(newValue) => setFieldValue(`person.isPoliticallyExposed`, newValue)}
                        />
                      </PepCheckbox>
                    </div>
                  </Grid3>
                )}
              </Section>
              <Buttons>
                {isSaving && isSubmitting && <StyledLoad />}

                {(isSubmitting || isDirty) && !(isSaving && isSubmitting) && (
                  <BoxButton
                    aria-label="Cancel"
                    onClick={() => {
                      handleReset();
                      mutatePersonRoles();
                    }}
                    padding="10px 20px"
                    title=""
                    type="button"
                    inverted
                  >
                    CANCEL
                  </BoxButton>
                )}
                {!(isSaving && isSubmitting) && (
                  <BoxButton
                    aria-label="Save"
                    disabled={isSubmitting || !dirty}
                    onClick={() => {
                      handleSubmit(values, setSubmitting);
                    }}
                    padding="10px 20px"
                    type="button"
                  >
                    SAVE
                  </BoxButton>
                )}
                {isSubmitted && !errorMessage && !dirty && !isRemoving && (
                  <SuccessMessage>
                    <StyledApproved />
                    <span>The role has been saved</span>
                  </SuccessMessage>
                )}
                {errorMessage && !dirty && !isRemoving && (
                  <ErrorMessage>
                    <div>Failed to save role!</div>
                    <div>{errorMessage}</div>
                  </ErrorMessage>
                )}
              </Buttons>
              <SecondTitle>
                {othersPersonRoles.length > 0
                  ? 'This contact do also have these roles:'
                  : "This contact doesn't have any other roles."}
              </SecondTitle>
              {othersPersonRoles.length > 0 && (
                <PersonsTable>
                  <TableHeader>
                    <div>Company</div>
                    <div>Role</div>
                    <div>In Role</div>
                  </TableHeader>
                  {othersPersonRoles.map((role, index) => {
                    return (
                      <TableRow key={index}>
                        <LinkToCompany
                          to={{
                            pathname: `/companies/${role[entityType].id}`,
                            state: {
                              clientId: role[entityType].id,
                            },
                          }}
                        >
                          {role[entityType].displayName}
                        </LinkToCompany>
                        <div>{role.role.type}</div>
                        <div>
                          {role.role.activePeriod &&
                            role.role.activePeriod.from &&
                            getFriendlyDate(role.role.activePeriod.from)}{' '}
                          &nbsp; - &nbsp;
                          {role.role.activePeriod &&
                            role.role.activePeriod.to &&
                            getFriendlyDate(role.role.activePeriod.to)}
                        </div>
                      </TableRow>
                    );
                  })}
                </PersonsTable>
              )}
            </Wrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

EditPerson.propTypes = {
  singleCompanyId: PropTypes.string,
};
