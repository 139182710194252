import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { InterestCodes } from '../interests/InterestCodes';
import { DownArrow, Close } from '@instech/icons';
import { useCompanyContext } from './CompanyContext';
import { constants } from '../../utils/constants';
import { StatusIcons } from '../shared/kyc-icons/StatusIcons';
import { RevalidateResyncButtons } from '../shared/RevalidateResyncButtons';
import { useAppContext } from '../appRouting/AppContext';

const StyledClose = styled(Close)`
  float: right;
  &:hover {
    transform: scale(1.3);
  }
`;
const UpArrow = styled(DownArrow)`
  transform: rotate(180deg);
  color: ${(props) => props.theme.marineBlue};
`;
const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  border-radius: 2px;
  padding: 14px;
  margin-bottom: 16px;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};
  transition: 0.3s;

  &:hover {
    background: ${( props ) => ( props.theme.flatWhite )};
    cursor: pointer;
  }

`;
const Title = styled.div`
  font-size: 12px;
  font-weight: bold;
`;
const HeaderContent = styled.div`
  display: grid;
  grid-template-columns:
    minmax(200px, 1fr) minmax(60px, 120px) minmax(60px, 120px) minmax(150px, 1fr)
    minmax(100px, 1fr);
  grid-column-gap: 24px;
`;
const HeaderItem = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  justify-items: start;
  flex-wrap: wrap;
  margin-right: 24px;
  min-width: 100px;
`;
const CompanyName = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  justify-items: start;
  flex-wrap: wrap;
  margin-right: 24px;
`;
const ChevronWrapper = styled.div`
  min-width: 30px;
  align-self: center;
  justify-self: right;
  &:hover {
    cursor: pointer;
  }
`;
const CompanyNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const CompanyHeader = () => {
  const {
    isClientOpen,
    clientData,
    organization: openClientData,
    roleHeader,
    clientRoleType,
    validations,
    covers,
    isTitleAsAssured,
    roleSourceSystem,
    isCompanyRole,
    fleetCovers,
    singleCompanyId,
    fleetId,
    openClientId,
    currentClientId,
    showConfirmClose,
    setShowConfirmModal,
    setOpenClientId,
    appDispatch,
    setLocationState,
    entityType,
    roleId,
  } = useCompanyContext();

  const { isAdministrator, isDataAdministrator } = useAppContext();

  const navigate = useNavigate();

  const clickClientHandler = (id) => {
    if (openClientId === id) {
      if (showConfirmClose) {
        setShowConfirmModal(true);
      } else {
        setOpenClientId(null);
        appDispatch && appDispatch({ type: constants.SET_SINGLE_COMPANY_ID, value: null });
        setLocationState &&
          setLocationState((prev) => ({
            ...prev,
            clientId: null,
          }));
        !!singleCompanyId && !fleetId && navigate('/companies', { replace: true });
      }
    } else {
      setOpenClientId(id);
      setLocationState &&
        setLocationState((prev) => ({
          ...prev,
          clientId: id,
        }));
    }
  };

  const roleTitle = !!roleHeader
    ? roleHeader
    : !!clientRoleType?.roleName
    ? clientRoleType?.roleName
    : clientRoleType?.roleType?.roleName === 'Owner' && isTitleAsAssured
    ? 'Owner as Assured'
    : !!clientRoleType?.roleType?.roleName
    ? clientRoleType?.roleType?.roleName
    : 'Company';

  if (!openClientData && !clientData) return '';
  return (
    <>
      <HeaderWrapper
        data-test-id="header-wrapper"
        onClick={() => clickClientHandler(currentClientId)}
      >
        <HeaderContent>
          <CompanyNameWrapper>
            <CompanyName>
              <Title>{roleTitle}</Title>
              <div>{clientData.displayName || clientData.name}</div>
            </CompanyName>
            {(isAdministrator || isDataAdministrator) && isCompanyRole && roleHeader != 'Client' && (
              <RevalidateResyncButtons entityId={roleId} entityType={entityType} entityPartitionKey={null} />
            )}
            <StatusIcons validations={validations} entityType={isCompanyRole ? 'CompanyRole' : 'Company'} />
          </CompanyNameWrapper>

          <HeaderItem>
            <Title>{roleSourceSystem && 'Source System'}</Title>
            <span>{roleSourceSystem === 'Ins2000' ? 'INS+' : roleSourceSystem}</span>
          </HeaderItem>

          <HeaderItem>
            <Title>Org.Number</Title>
            <span>{clientData.organizationNumber}</span>
          </HeaderItem>

          <HeaderItem>
            <Title>Address</Title>
            <span>{clientData.displayAddress}</span>
          </HeaderItem>

          {covers && (roleSourceSystem === 'Ins2000' || roleSourceSystem === 'INS+') ? (
            <HeaderItem>
              <Title>Interests</Title>
              <InterestCodes covers={covers} fleetCovers={fleetCovers} />
            </HeaderItem>
          ) : null}
        </HeaderContent>
        <ChevronWrapper>
          {singleCompanyId && !fleetId ? <StyledClose /> : isClientOpen ? <UpArrow /> : <DownArrow />}
        </ChevronWrapper>
      </HeaderWrapper>
    </>
  );
};
