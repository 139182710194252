import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getFriendlyDate } from '../../utils/date';
import { useEventStream } from '../../services/useEventStream';
import { Link } from 'react-router-dom';

const TableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  align-items: center;
  padding: 10px 20px;
  
  &:nth-child(odd) {
    background: ${(props) => props.theme.flatWhite};
  }
`;
const LinkToCompany = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
`;

export const PersonRoleRow = ({
  item,
  mutate,
  isSaving,
  isEditPersonOpen,
  isDirty,
  setShowConfirmRefresh,
  setUpdatedBy,
}) => {
  const entityType = item.$type;
  const eventStreamTopic = isEditPersonOpen
    ? entityType === 'organization'
      ? `/Organization/${item[entityType].id}/${item[entityType].id}/*`
      : `/InsuredObject/${item[entityType].partitionKey}/${item[entityType].id}/*`
    : null;

  const { onEvent, onConnect, onDisconnect, eventHandlerCleanup } = useEventStream(eventStreamTopic);

  onConnect(
    useCallback(() => {
      console.log('Connected to event stream for', eventStreamTopic);
    }, [eventStreamTopic])
  );

  onDisconnect(
    useCallback(() => {
      console.log('Disconnected from event stream for', eventStreamTopic);
    }, [eventStreamTopic])
  );
  useEffect(() => {
    onEvent((event) => {
      console.info('Received event on topic', eventStreamTopic, event);

      setUpdatedBy(event.user?.displayName);

      if (!isSaving) {
        if (isDirty) {
          setShowConfirmRefresh(true);
        } else {
          setShowConfirmRefresh(false);
          // Tell SWR to reload data from server
          mutate();
        }
      }
    });

    return eventHandlerCleanup;
  }, [mutate, eventStreamTopic, setShowConfirmRefresh, isSaving, isDirty]);

  return (
    <TableRow>
      {entityType === 'organization' ? (
        <LinkToCompany
          to={{
            pathname: `/companies/${item[entityType].id}`,
            state: {
              clientId: item[entityType].id,
            },
          }}
        >
          {item[entityType].displayName}
        </LinkToCompany>
      ) : (
        <span>{item[entityType].displayName}</span>
      )}
      <div>{item.role.type}</div>
      <div>
        {item.role.activePeriod?.from && getFriendlyDate(item.role.activePeriod.from)} &nbsp; -
        &nbsp;
        {item.role.activePeriod?.to && getFriendlyDate(item.role.activePeriod.to)}
      </div>
    </TableRow>
  );
};

PersonRoleRow.propTypes = {
  item: PropTypes.object,
  mutate: PropTypes.func,
  isSaving: PropTypes.bool,
  isEditPersonOpen: PropTypes.bool,
  isDirty: PropTypes.bool,
  setShowConfirmRefresh: PropTypes.func,
  setUpdatedBy: PropTypes.func,
};
